import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { SalesChannel } from '../models/SalesChannel/SalesChannel';
import { SalesChannelTree } from '../models/SalesChannel/SalesChannelTree';
import { UserSalesChannel } from '../models/SalesChannel/UserSalesChannel';

@Injectable({
    providedIn: 'root'
})
export class SalesChannelService {
    private readonly api: string;

    constructor(private readonly http: HttpClient) {
        this.api = environment.apiEndpoint;
    }

    getForUser() {
        return this.http.get<UserSalesChannel[]>(`${this.api}/saleschannel`);
    }

    getAllChannels() {
        return this.http.get<SalesChannelTree[]>(`${this.api}/saleschannel/all`);
    }

    getSalesChannelsBySalesId() {
        return this.http.get<SalesChannel[]>(`${this.api}/saleschannel/activechannelandchildren`);
    }
}

