import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../environments/environment";
import { SupportInformation } from '../models/SupportInformation';
import { catchError } from 'rxjs/operators';
import { ProductivityInformation } from "../models/ProductivityInformation/ProductivityInformation";
import { AgentMessage } from "../models/ProductivityInformation/AgentMessage";
import { AgentLink } from "../models/ProductivityInformation/AgentLink";
import { AgentMessageSeverity } from "../models/ProductivityInformation/AgentMessageSeverity";

@Injectable({
    providedIn: 'root'
})
export class InformationService {
    private readonly api: string;

    constructor(private http: HttpClient) {
        this.api = environment.apiEndpoint;
    }

    // --- Site wide Information ---

    getSupportInformation(): Observable<SupportInformation> {
        return this.http.get<SupportInformation>(`${this.api}/info/support`).pipe(
            catchError(_ => {
                // Need to / want to return SupportInformation ultimately
                return of(new SupportInformation());
            })
        );
    }

    getProductivityInformation(salesId: number): Observable<ProductivityInformation> {
        return this.http.get<ProductivityInformation>(`${this.api}/info/productivity/${salesId}`);
    }

    addMessage(salesId: number, agentMessage: AgentMessage): Observable<any> {
        if(salesId == -1) {
            return this.http.post(`${this.api}/info/productivity/admin/messages`, agentMessage);
        }

        return this.http.post(`${this.api}/info/productivity/${salesId}/messages`, agentMessage);
    }

    addLink(salesId: number, agentLink: AgentLink): Observable<any> {
        if(salesId == -1) {
            return this.http.post(`${this.api}/info/productivity/admin/links`, agentLink);
        }

        return this.http.post(`${this.api}/info/productivity/${salesId}/links`, agentLink);
    }

    // --- Channel Information ---

    dismissChannelMessage(salesId: number, messageId: number): Observable<any> {
        return this.http.delete(`${this.api}/info/productivity/${salesId}/messages/${messageId}`);
    }

    dismissChannelLink(salesId: number, linkId: number): Observable<any> {
        return this.http.delete(`${this.api}/info/productivity/${salesId}/links/${linkId}`);
    }

    // --- Admin Information ---

    getAdminProductivityInformation(allInformation: boolean = false): Observable<ProductivityInformation> {
        return this.http.get<ProductivityInformation>(`${this.api}/info/productivity/admin?allInformation=${allInformation}`);
    }

    dismissAdminMessage(messageId: number): Observable<any> {
        return this.http.delete(`${this.api}/info/productivity/admin/messages/${messageId}`);
    }

    dismissAdminLink(linkId: number): Observable<any> {
        return this.http.delete(`${this.api}/info/productivity/admin/links/${linkId}`);
    }

    // Gets the MAT-ICON for a given agent message severity level
    // See https://fonts.google.com/icons
    getMessageSeverityIcon(severity: AgentMessageSeverity) : string {
        if(severity == AgentMessageSeverity.Alert) { // Alert
            return 'priority_high'
        } else if(severity == AgentMessageSeverity.Critical) { // Critical
            return 'warning';
        } else if(severity == AgentMessageSeverity.Warning) { // Warning
            return 'notification_important';
        } else if(severity == AgentMessageSeverity.Information) { // Info
            return 'info';
        } else { // default or other
            return 'announcement';
        }
    }

    setSeverityColorClass(severity: AgentMessageSeverity) {
        if (severity == AgentMessageSeverity.Alert) {
            return 'icon--alert';
        } else if(severity == AgentMessageSeverity.Critical) {
            return 'icon--critical';
        } else if (severity == AgentMessageSeverity.Warning) {
            return 'icon--warn';
        } else if (severity == AgentMessageSeverity.Information) {
            return  'icon--info';
        } else {
            return ''; // if severity is set incorrectly or is anything else then display normal
        }
    }
}
