import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from "../../services/error.service";

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {

    constructor(private errorService: ErrorService) {
        super();
    }

    handleError(error: any): void {
       this.errorService.onConsoleError(error);
    }
}
