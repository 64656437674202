// Based on https://stackoverflow.com/questions/61341891/is-there-are-angular-date-ago-pipe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'TimeSince'
})
export class TimeSincePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            let seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            let suffix = "ago";
            if(Math.sign(seconds) == -1 ){
                seconds = -seconds; // flip the seconds sign if date in future
                suffix = "from now";
            }
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            const intervals: { [key: string]: number } = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    return this.calculateTimeString(counter, i, suffix);
                }
            }
        }
        return value;
    }

    calculateTimeString(counter : number, timeUnit : string, suffix: string ){
        if (counter === 1) {
            return counter + ' ' + timeUnit + ' ' + suffix; // singular (1 day ago)
        } else {
            return counter + ' ' + timeUnit + 's' + ' ' + suffix; // plural (2 days ago)
        }
    }

}
