import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {
    constructor(private titleService: Title) { }

    setPageName(name: string) {
        this.titleService.setTitle(`${name} | Commerce Portal`);
    }

    isInIframe(): boolean {
        // top and self are both window objects so we're seeing if our window is the top window.
        try {
            return window.self !== window.top;
        } catch (e) {
            // sometimes access to top is blocked by CORS, which also indicates we are in an iframe
            return true;
        }
    }

    isInPopup() : boolean {
        return window.opener && window.opener !== window;
    }

    // if we cannot parse a url it is assumed to be a relative path
    parseUrl(urlString: string): URL {
        try {
            return new URL(urlString);
        }
        catch (e: any) {
            return null;
        }
    }
}
