import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {UserService} from '../../services/user.service';
import {UserSalesChannel} from "../../models/SalesChannel/UserSalesChannel";

@Injectable()
export class SalesChannelInterceptor implements HttpInterceptor {
    activeSalesChannel: UserSalesChannel;

    constructor(private userService: UserService) {
        this.userService.activeSalesChannel.subscribe(activeSalesChannel => this.activeSalesChannel = activeSalesChannel);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Add salesId header when we make requests only to sphinnx API
        if (this.activeSalesChannel && req.url.includes(environment.apiEndpoint)) {
            const authReq = req.clone({
                headers: req.headers.set('salesId', this.activeSalesChannel.id.toString())
            });

            return next.handle(authReq);
        }

        return next.handle(req);
    }
}
