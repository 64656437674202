import { Injectable, Inject } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class LegacySessionService {
    constructor(private cookieService: CookieService) {}

    getUserSessionId() {
        return this.cookieService.get('usersessionid');
    }

    getAgentSessionId() {
        return this.cookieService.get('agentsessionid');
    }

    getActiveItinId() {
        return this.cookieService.get('itineraryid');
    }

    getSalesId() {
        return this.cookieService.get('salesid');
    }
}
