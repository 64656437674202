import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LeastRecentlyUsedCache } from "../models/LeastRecentlyUsedCache";
import { UserService } from "../services/user.service";
import { SalesChannelForm } from '../shared/saleschannelForm.component';
import { LegacySessionService } from '../services/legacysession.service';
import { CookieService } from "../services/cookie.service";
import { ErrorService } from "../services/error.service";
import { BrowserService } from '../services/browser.service';
import { SalesChannel } from "../models/SalesChannel/SalesChannel";
import { SupplierCategoryType } from "../models/SalesChannel/SupplierCategoryType";

@Injectable()
export class SalesChannelSelectorService {

    // Recents storage Data
    private readonly numberOfRecents: number = 3;
    recentSalesChannels = new LeastRecentlyUsedCache<SalesChannel>(this.numberOfRecents);

    constructor(
        private errorService: ErrorService,
        private route: ActivatedRoute,
        private router: Router,
        public userService: UserService,
        private legacySessionService: LegacySessionService,
        private cookieService: CookieService,
        private browserService: BrowserService
    )
    {

        // Initially load recently used from storage
        this.recentSalesChannels.fromJson(this.cookieService.getRecentlyUsed());
        this.filterRecentlySelected(); // Filter what comes in to protect against bad data
    }

    selectSalesChannel = (salesId: number, legacyForm: SalesChannelForm = null) => {

        this.userService.upgrade(salesId).subscribe(
            channel => {
                let salesChannel = new SalesChannel( {
                    id: channel.id,
                    name: channel.name,
                    supplierCategoryId: channel.supplierCategoryId,
                })
                salesChannel.test = channel.isTest;
                salesChannel.resellerActive = channel.isActive;
                salesChannel.hasAccess = true;

                if(salesChannel.supplierCategoryId == SupplierCategoryType["Central Reservations"]) {
                    this.setRecentlySelected(salesChannel);
                }

                if (legacyForm != null) {
                    legacyForm.submit(salesId, this.legacySessionService.getUserSessionId(), false);
                }

                // we only redirect to relative URLs from the channel selector
                // to avoid confusing UX with applications like CRS and Agentopia
                let returnUrl = this.route.snapshot.queryParams['redirect_uri'];
                if (returnUrl && this.browserService.parseUrl(returnUrl) == null) {
                    this.router.navigate([returnUrl.toLowerCase()])
                        .catch(() => this.errorService.onConsoleError(`Error navigating to ${returnUrl.toLowerCase()}`));
                }
                else {
                    this.router.navigate(['home'])
                        .catch(() => this.errorService.onConsoleError(`Error navigating to home`));
                }
            },
            error => this.errorService.onError(error)
        );
    }

    getRecentlySelected(): SalesChannel[] {

        let channels: SalesChannel[] = [];
        this.recentSalesChannels.entries.forEach(recent => {
            channels.push(recent[1])
        });
        return channels;
    }

    removeRecentlySelected(salesId: number) {
        this.recentSalesChannels.delete(salesId.toString());
        this.cookieService.setRecentlyUsed(this.recentSalesChannels.toJson());
    }

    private setRecentlySelected(salesChannel: SalesChannel) {
        this.recentSalesChannels.put(salesChannel.id.toString(), salesChannel);
        this.cookieService.setRecentlyUsed(this.recentSalesChannels.toJson());
    }

    private filterRecentlySelected() {
        const recents = new Array(...this.recentSalesChannels.entries);
        recents.forEach(recent => {
            const recentSalesChannelId = recent[0];
            const resentSalesChannel: SalesChannel = recent[1];

            // Remove items from the recently used list
            // that do not have an id in the model
            // that are no longer available for the user to select
            // that are not a CRS channel
            if (resentSalesChannel.id === undefined ||
                !resentSalesChannel.hasAccess ||
                resentSalesChannel.supplierCategoryId != SupplierCategoryType["Central Reservations"]) {
                this.recentSalesChannels.delete(recentSalesChannelId);
            }

        });
    }



}

