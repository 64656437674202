import {DefaultUrlSerializer, UrlTree} from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const route = url.split('?');
        const query = route[1];

        if(query === undefined) {
            return super.parse(route[0].toLowerCase());
        } else {
            return super.parse(route[0].toLowerCase() + "?" + query);
        }
    }
}
