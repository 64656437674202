import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import { environment } from '../environments/environment';

// global modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// global components
import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from './header.component';
import { FooterComponent } from "./footer.component";

// global services
import { LowerCaseUrlSerializer } from "./core/providers/urlSerializer.service";
import { GlobalErrorHandlerService } from "./core/providers/global-errorHandler.service";
import { HomeModule } from "./home/home.module";
import { AppInitializerService } from "./core/providers/appInitializer.service";

@NgModule({
    providers: [
        { provide: APP_BASE_HREF, useValue: environment.appBase },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        { provide: APP_INITIALIZER, useFactory: (appInitializerService: AppInitializerService) => appInitializerService.initializeAppFactory(), deps: [AppInitializerService], multi: true },
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        JwtHelperService
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [
        // Material Modules
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        // Custom Modules
        SharedModule,
        HomeModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
