import { HttpBackend, HttpClient, HttpRequest, HttpResponse } from "@angular/common/http";
import { CookieService } from "../../services/cookie.service";
import { UserService } from "../../services/user.service";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";
import { filter, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ErrorService } from "../../services/error.service";


@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {
    constructor(
        private httpBackend: HttpBackend,
        private cookieService: CookieService,
        private userService: UserService,
        private errorService: ErrorService
    ) {}

    initializeAppFactory(): () => Observable<any> {
        let request = new HttpRequest(
            "GET",
            `${environment.apiEndpoint}/Login/auth`,
            {responseType: "text", withCredentials: true, reportProgress: false},
        );
        return () => new HttpClient(this.httpBackend).request(request)
            .pipe(
                filter(event => event instanceof HttpResponse),
                map((response: HttpResponse<any>) => response.body),
                tap({
                    next: val => {
                        if (val && typeof val == "string") {
                            this.cookieService.setJWTToken(val);
                            this.userService.init();
                        }
                    }, error: err => this.errorService.onConsoleError(err)
                }));
    }

}
