import { Injectable } from '@angular/core';
import { UserSalesChannel } from "../models/SalesChannel/UserSalesChannel";
import { JWTUser } from "../models/User/JWTUser";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    private readonly sphinnxChannelKey : string = 'sphinnx-channel';
    private readonly recentsStorageKey: string = 'saleschannelselector.recents';
    private readonly dismissibleMessageKey: string = 'dismissMessages';

    // JWT CLAIM NAMES
    private readonly JWT_Authentication: string = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication";
    private readonly JWT_Name: string = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";

    private token: string;

    constructor(private jwtService: JwtHelperService) {}

    removeActiveChannel() {
        sessionStorage.removeItem(this.sphinnxChannelKey);
    }

    setActiveChannel(channel: UserSalesChannel) {
        sessionStorage.setItem(this.sphinnxChannelKey, JSON.stringify(channel));
    }

    getActiveChannel(): UserSalesChannel {
        const channel = sessionStorage.getItem(this.sphinnxChannelKey);

        if (channel) {
            return JSON.parse(channel);
        } else {
            return null;
        }
    }

    getJWTToken(): string {
        return this.token;
    }

    // NOTE: JWT Token is managed by the API
    // Token is set to a UI variable and not pulled from a cookie since setting it on the API side would take too much time
    setJWTToken(token: string) {
        this.token = token;
    }

    setRecentlyUsed(recents: string) {
        localStorage.setItem(this.recentsStorageKey + "." + this.getJWTUser().userId, recents);
    }

    getRecentlyUsed() : string {
        return localStorage.getItem(this.recentsStorageKey + "." + this.getJWTUser().userId);
    }

    /*
    Gets the JWT data model. Only populates authentication and userId fields.
    All other fields should not be used as user information can be altered during the same authentication token.
    Returns null if the user is not authenticated (no token).
     */
    getJWTUser() : JWTUser {

        const token = this.getJWTToken()

        if (!token || this.jwtService.isTokenExpired(token)) {
            return null;
        }

        const decodedToken = this.jwtService.decodeToken(token);
        return {
            role: null,
            authentication: decodedToken[this.JWT_Authentication],
            emailAddress: null,
            givenName: null,
            homePhone: null,
            userId: decodedToken[this.JWT_Name],
            sessionId: null,
            surname: null,
            system: null,
        };
    }

    removeDismissibleMessage(messageId: number) {
        let newList = this.getDismissibleMessageIds();
        if(newList) {
            let index = newList.findIndex(item => item == messageId);
            newList.splice(index, 1);
            this.setDismissibleMessageIds(newList);
        }

    }

    addDismissibleMessage(messageId: number) {
        let newList : number[] = this.getDismissibleMessageIds() || [];
        newList.push(messageId);
        this.setDismissibleMessageIds(newList);
    }

    setDismissibleMessageIds(messageIds: number[]) {
        localStorage.setItem(this.dismissibleMessageKey + "." + this.getJWTUser().userId, JSON.stringify(messageIds));
    }

    getDismissibleMessageIds() : number[] {
        let user = this.getJWTUser()?.userId;
        if(user){
            const rawIds = localStorage.getItem(this.dismissibleMessageKey + "." + user);
            if (rawIds) return JSON.parse(rawIds);
        }
        return null;

    }
}
