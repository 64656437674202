import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthGuard } from './routeGuards/auth-guard.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor
        (
        private snackBar: MatSnackBar,
        private authGuard: AuthGuard,
        private router: Router
        ) { }

    private snackbarConfig: MatSnackBarConfig = {
        duration: 8000,
        verticalPosition: 'top'
    };

    onError(error: any, debug?: boolean, customMessage?: string) {
        let errorConfig = Object.assign({}, this.snackbarConfig);
        errorConfig.panelClass = 'mat-error';

        if (error.status == 401) {
            this.authGuard.loginRedirect(this.router.url);
        } else if (error.status == 403) {
            this.snackBar.open('You do not have permission to do this', 'Dismiss', errorConfig);
        } else if (customMessage && typeof customMessage === 'string') {
            this.snackBar.open(customMessage, 'Dismiss', errorConfig);
        } else {
            this.snackBar.open('Something went wrong', 'Dismiss', errorConfig);
        }

        if (debug) {
            console.debug(error);
        }
    }

    // Prints a standardized console error
    onConsoleError(message?: string) {
        if(message && typeof message == 'string') {
            console.error(`Please contact support | ${message}`);
        } else {
            console.error("Please contact support | An error occurred");
        }

    }
}
