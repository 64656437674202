import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import {CookieService} from "../../services/cookie.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const authToken = this.cookieService.getJWTToken();

        // only modify the request if the token is set
        if (authToken && (req.url.includes(environment.apiEndpoint) || req.url.includes('IdeasG3'))) {
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken),
                withCredentials: req.url.includes('localhost') // Add Cookies to each request in local dev for CORS
            });

            return next.handle(authReq);
        }

        return next.handle(req);
    }
}
