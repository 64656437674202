import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';

@Injectable()
export class JsonInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!req.url.includes('/ecomm/')) {
            const authReq = req.clone({
                headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
                                    .append('Cache-Control', 'no-cache') // HTTP 1.1
                                    .append('Pragma', 'no-cache') // HTTP 1.0 (IE11)
                                    .append('Expires', '0')
            });
            return next.handle(authReq);
        }

        return next.handle(req);
    }
}
