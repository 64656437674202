import { Component, OnInit } from '@angular/core';
import { BrowserService } from './services/browser.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'Welcome to Sphinnx';

    isInIframe: boolean;

    constructor(private frameService: BrowserService) { }

    ngOnInit(): void {
        this.isInIframe = this.frameService.isInIframe();
    }
}
