import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { UserSalesChannel } from '../models/SalesChannel/UserSalesChannel';
import { SalesChannelService } from "./saleschannel.service";
import { CookieService } from "./cookie.service";
import { PhoneAuthType } from '../models/Login/phoneAuthType';
import { AppIdCodes } from "../models/Login/AppIdCodes";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly api: string;
    private authenticationTracker = new BehaviorSubject<boolean>(false);
    private activeSalesChannelTracker = new BehaviorSubject<UserSalesChannel>(null);
    authenticationState = this.authenticationTracker.asObservable();
    activeSalesChannel = this.activeSalesChannelTracker.asObservable();

    constructor(
        private http: HttpClient,
        private jwtService: JwtHelperService,
        private salesChannelService: SalesChannelService,
        private cookieService: CookieService
    ) {
        this.api = environment.apiEndpoint;
    }

    init() {
        // don't set active channel if user is not authenticated
        if (this.isAuthenticated()) {
            let activeChannel = this.cookieService.getActiveChannel();
            if (activeChannel) {
                this.activeSalesChannelTracker.next(activeChannel);
            }
        }
        else {
            this.removeActiveChannel();
        }
    }

    login(username: string, password: string, appId: AppIdCodes): Observable<any> {

        const body = {
            username: username,
            password: password,
            appId: appId
        };

        const requestUri = `${this.api}/login`;

        return this.http.post(requestUri, body, { withCredentials: true })
            .pipe(
                map((data: any) => {
                    // This endpoint can return one of two models depending on the MFA workflow,
                    // and we only want to process the UserAuth model when a session is present
                    if (data.session != null) {
                        this.cookieService.setJWTToken(data['token']);
                        this.authenticationTracker.next(true);
                    }

                    return data;
                })
            );
    }

    verifyOneTimeCode(username: string, password: string, oneTimeCode: string): Observable<any> {

        const body = {
            username: username,
            password: password,
            oneTimeCode: oneTimeCode
        };

        const requestUri = `${this.api}/login/verifyCode`;

        return this.http.post(requestUri, body, { withCredentials: true })
            .pipe(
                map((data: any) => {
                    // This endpoint can return one of two models depending on the MFA workflow,
                    // and we only want to process the UserAuth model when a session is present
                    if (data.session != null) {
                        this.cookieService.setJWTToken(data['token']);
                        this.authenticationTracker.next(true);
                    }

                    return data;
                })
            );
    }

    sendMfaPhoneOneTimeCode(username: string, password: string, channel: PhoneAuthType): Observable<any> {

        const body = {
            username: username,
            password: password
        };

        const requestUri = `${this.api}/login/sendcode/${channel}`;

        return this.http.post(requestUri, body, { withCredentials: true })
            .pipe(
                map((data: any) => {

                    return data;
                })
            );
    }

    /**
     * Makes the sales channel the active channel
     * The API does the logic to verify if the user can access it.
     * @param channelId the id of the sales channel
     */
    upgrade(channelId: number): Observable<UserSalesChannel> {
        return this.http.post<UserSalesChannel>(`${this.api}/saleschannel/select/${channelId}`, {}, { withCredentials: true }).pipe(
            map(channel => {
                this.cookieService.setActiveChannel(channel);
                this.activeSalesChannelTracker.next(channel);
                return channel;
            }));
    }

    clearSessionState(logoutRedirect: boolean = true) {
        this.authenticationTracker.next(false);
        this.removeActiveChannel();
        if(logoutRedirect) window.location.href = this.api + '/logout';
    }

    isAuthenticated(): boolean {
        // User is authenticated if they 1) have a token, 2) the JWT is active
        const authenticated = this.cookieService.getJWTToken() && !this.jwtService.isTokenExpired(this.cookieService.getJWTToken());
        this.authenticationTracker.next(authenticated);
        return authenticated;
    }

    isAdmin(): boolean {
        return this.cookieService.getJWTUser()?.authentication == 'inntopiaadmin';
    }

    removeActiveChannel() {
        this.cookieService.removeActiveChannel();
        this.activeSalesChannelTracker.next(null);
    }

    refreshActiveSalesChannel(){

        this.salesChannelService.getForUser()
            .subscribe(userSalesChannels => {

                for (const channel of userSalesChannels) {

                    // update channel with new one from API
                    if(this.activeSalesChannelTracker.value.id == channel.id) {
                        this.removeActiveChannel();
                        this.upgrade(channel.id).subscribe();
                        break;
                    }

                }
            }, error => {
                console.log("Could not refresh active sales channel");
            });

    }

}
