import { ISalesChannel } from "./ISalesChannel";
import { SupplierCategoryType } from "./SupplierCategoryType";
import { ParentType } from "./ParentType";

export class SalesChannel implements ISalesChannel {
    id: number;
    name: string;
    supplierCategoryId: SupplierCategoryType;
    parentType: ParentType;

    parent: number;
    reservationsEnabled: boolean;
    resellerActive: boolean;
    test: boolean;
    hasAccess: boolean;

    public constructor(fields?: {
        id?: number,
        name?: string,
        supplierCategoryId?: SupplierCategoryType;
    }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

