import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../user.service';
import { BrowserService } from '../browser.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router, private iframeService: BrowserService){}

    canActivate(targetRoute: ActivatedRouteSnapshot): boolean {

        if (!this.userService.isAuthenticated()) {
            this.loginRedirect(targetRoute.routeConfig.path);
            return false;
        }
        return true;
    }

    loginRedirect(destination: string) {
        if (this.iframeService.isInIframe()) {
            window.top.location.replace('/ui/login?renew=true');
        } else {
            this.router.navigate(['login'], { queryParams: { 'redirect_uri': destination, 'renew': true } })
                .catch(() => console.error(`Error navigating to login in authGuard`));
        }
    }
}
