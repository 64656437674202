import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Directive({
    selector: '[debounceInput]'
})
export class DebounceInputDirective implements OnInit {
    @Input() debounceTime = 500;
    @Output() debounceKeyUp = new EventEmitter();
    private onKeyUp = new Subject();
    private subscription: Subscription;

    ngOnInit() {
        this.subscription = this.onKeyUp.pipe(
            debounceTime(this.debounceTime)
        ).subscribe(e => this.debounceKeyUp.emit(e));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('keyup', ['$event'])
    keyupEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.onKeyUp.next(event);
    }
}
