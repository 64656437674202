export enum SupplierCategoryType {
    'Travel Agent' = 4,
    'Tour Operator' = 7,
    'Central Reservations' = 8,
    'Online Reseller' = 10,
    'Lodging Property' = 12,
    'Activity Provider' = 501,
    'Event Coordinator' = 502,
    'Equipment Rental' = 503,
    'Transportation Provider' = 504,
    'Location' = 505,
    'Travel Insurance' = 506
}
