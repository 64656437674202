// based on https://medium.com/spektrakel-blog/a-simple-lru-cache-in-typescript-cba0d9807c40

export class LeastRecentlyUsedCache<T> {
    private localValues = new Map<string, T>();
    private readonly maxEntries: number = 20;

    constructor(maxEntries?: number) {
        if (maxEntries) {
            this.maxEntries = maxEntries;
        }
    }

    get length(): number {
        return this.localValues.size;
    }

    // Returns the entries newest to oldest
    get entries(): [string, T][] {
        return Array.from(this.localValues.entries()).reverse();
    }

    get(key: string): T {
        const hasKey = this.localValues.has(key);
        let entry: T = null;
        if (hasKey) {
            // peek the entry, re-insert for LRU strategy
            entry = this.localValues.get(key);
            this.localValues.delete(key);
            this.localValues.set(key, entry);
        }

        return entry;
    }

    put(key: string, value: T) {
        if (this.localValues.has(key)) {
            this.localValues.delete(key);
        } else if (this.localValues.size >= this.maxEntries) {
            const keyToDelete = this.localValues.keys().next().value;
            this.localValues.delete(keyToDelete);
        }

        this.localValues.set(key, value);
    }

    delete(key: string): T {
        let entry: T = null;
        if (this.localValues.has(key)) {
            entry = this.localValues.get(key);
            this.localValues.delete(key);
        }
        return entry;
    }

    toJson(): string {
        return JSON.stringify(Array.from(this.localValues.entries()));
    }

    fromJson(json: string) {
        if (json) {
            try {
                const jObject = JSON.parse(json);
                this.localValues = new Map(jObject);
            } catch (ex) {
                if (console) {
                    console.log('Could not load recently used cache from input json.');
                    console.log(ex);
                }
            }
        }         
    }
}
