import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserService } from './services/browser.service';
import { UserService } from './services/user.service';
import { UserSalesChannel } from './models/SalesChannel/UserSalesChannel';
import { LegacySessionService } from './services/legacysession.service';
import { SalesChannelForm } from './shared/saleschannelForm.component';
import { Health } from "./models/Health";
import { StatusService } from "./services/status.service";
import { CookieService } from "./services/cookie.service";
import { AgentMessage } from "./models/ProductivityInformation/AgentMessage";
import { InformationService } from "./services/information.service";
import { AgentMessageSeverity } from "./models/ProductivityInformation/AgentMessageSeverity";
import { ErrorService } from "./services/error.service";
import { environment } from "../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    protected readonly environment = environment;
    isAuthenticated: boolean = false;
    isAdmin: boolean = false;
    authLinkText: string;
    userIdText: string;
    activeSalesChannel: UserSalesChannel;
    dismissibleMessages: AgentMessage[];
    status: Health;
    @ViewChild(SalesChannelForm, { static: false }) legacyForm: SalesChannelForm;

    constructor(
        private readonly router: Router,
        public readonly browserService: BrowserService,
        private readonly cookieService: CookieService,
        private readonly statusService: StatusService,
        private readonly userService: UserService,
        public readonly InfoService: InformationService,
        private readonly errorService: ErrorService,
        private readonly legacySessionService: LegacySessionService) {
    }

    ngOnInit() {
        this.userService.authenticationState.subscribe({
            next: isAuthenticated => {
                this.isAuthenticated = isAuthenticated;
                this.isAdmin = this.userService.isAdmin();

                if (isAuthenticated) {
                    this.authLinkText = 'Logout';
                } else {
                    this.authLinkText = 'Login';
                }

                this.loadDismissibleMessages();

            }
        });

        this.userService.activeSalesChannel.subscribe({
            next: activeSalesChannel => this.activeSalesChannel = activeSalesChannel
        });
        this.statusService.getStatus().subscribe({
            next: status => this.status = status,
            error: error => this.errorService.onConsoleError(`API Error | ${error}`)
        });

    }

    private loadDismissibleMessages() {
        this.InfoService.getAdminProductivityInformation().subscribe({
            next: value => {

                this.dismissibleMessages = value.agentMessages;
                this.dismissibleMessages = this.dismissibleMessages?.filter(message => message.dismissible);
                this.dismissibleMessages?.sort((a, b) => a.severity - b.severity);

                let dismissedMessageIds = this.cookieService.getDismissibleMessageIds();
                if(dismissedMessageIds) {
                    this.cookieService.getDismissibleMessageIds().forEach(id => {
                        let index = this.dismissibleMessages.findIndex(message => message.messageId == id);
                        if(index == -1) {
                            // Remove items from the local messageIds that no longer exist server side
                            this.cookieService.removeDismissibleMessage(id);
                        } else if(index != -1)  {
                            // remove items from the messages that have been previously dismissed
                            this.dismissibleMessages.splice(index, 1);
                        }
                    });
                }

            }
        });
    }

    loginAction() {
        this.router.navigate(['login'], { queryParams: { 'redirect_uri': this.router.url }})
            .catch(() => this.errorService.onConsoleError(`Error navigating to login`));
    }

    logoutAction() {
        this.userService.clearSessionState();
    }

    navigateToLegacyCrs() {
        this.legacyForm.submit(this.activeSalesChannel.id, this.legacySessionService.getUserSessionId(), false);
    }

    clearSalesChannel() {
        this.userService.removeActiveChannel();
    }

    get permissions(){
        if(!this.activeSalesChannel){
            return false;
        }
        return this.activeSalesChannel.permissions;
    }

    loadUserId() {
        this.userIdText = this.cookieService.getJWTUser().userId;
    }

    setSeverityColorClass(severity: AgentMessageSeverity) {
        if (severity == AgentMessageSeverity.Alert) {
            return 'dismissibleMessage--alert';
        } else if(severity == AgentMessageSeverity.Critical) {
            return 'dismissibleMessage--critical';
        } else if (severity == AgentMessageSeverity.Warning) {
            return 'dismissibleMessage--warn';
        } else if (severity == AgentMessageSeverity.Information) {
                return  'dismissibleMessage--info';
        } else {
            return ''; // if severity is set incorrectly or is anything else then display normal
        }
    }

    dismissMessage(message: AgentMessage) {
        if (this.isAuthenticated) {
            this.cookieService.addDismissibleMessage(message.messageId);
            let index = this.dismissibleMessages.findIndex(item => item.messageId == message.messageId);
            this.dismissibleMessages.splice(index, 1);
        }

    }
}
