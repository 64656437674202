import { NgModule } from "@angular/core";
import { CoreModule } from "../core/core.module";
import { SharedModule } from "../shared/shared.module";
import { HomeComponent } from "./home.component";
import { RouterLink } from "@angular/router";

@NgModule({
    imports: [
        CoreModule,
        SharedModule,
        RouterLink
    ],
    exports: [],
    declarations: [
        HomeComponent
    ]
})
export class HomeModule { }

