import { Component, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { UserSalesChannel } from '../models/SalesChannel/UserSalesChannel';
import { SalesChannelForm } from "../shared/saleschannelForm.component";
import { LegacySessionService } from "../services/legacysession.service";
import { environment } from "../../environments/environment";
import { BrowserService } from '../services/browser.service';
import { InformationService } from "../services/information.service";
import { ProductivityInformation } from "../models/ProductivityInformation/ProductivityInformation";
@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    isAuthenticated: boolean;
    activeSalesChannel: UserSalesChannel;
    productivityInformation: ProductivityInformation;
    productivityInformationSystem: ProductivityInformation;
    @ViewChild(SalesChannelForm, { static: false }) legacyForm: SalesChannelForm;

    constructor(
        private userService: UserService,
        private legacySessionService: LegacySessionService,
        private readonly browserService: BrowserService,
        private readonly infoService: InformationService
    ) {
        this.browserService.setPageName('Dashboard');
    }

    ngOnInit() {
        this.userService.authenticationState.subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
        this.userService.activeSalesChannel.subscribe({
            next: activeSalesChannel => {
                this.activeSalesChannel = activeSalesChannel;
                if(activeSalesChannel) {
                    this.infoService.getProductivityInformation(this.activeSalesChannel.id)
                        .subscribe({
                            next: (data: ProductivityInformation) => {
                                this.sortAndFilterInformation(data);
                                this.productivityInformation = data;
                            }
                        });
                }
            }
        });

        // System information is always gathered
        this.infoService.getAdminProductivityInformation()
            .subscribe({
                next: (data: ProductivityInformation) => {
                    this.sortAndFilterInformation(data);
                    this.productivityInformationSystem = data;
                }
            });
    }

    private sortAndFilterInformation(data: ProductivityInformation){
        // Only show messages that are active
        data.agentMessages = data.agentMessages.filter(message => new Date(message.dateActive).getTime() <= new Date().getTime());
        // Sort by date in ASC order
        data.agentMessages.sort((a, b) => new Date(b.dateActive).getTime() - new Date(a.dateActive).getTime());
    }

    navigateToLegacyCrs() {
        this.legacyForm.submit(this.activeSalesChannel.id, this.legacySessionService.getUserSessionId(), false);
    }

    navigateToRMS(){
        window.open(`https://${environment.rmsIntegration.domain}`, "_self");
    }

    showQuickLinks(): boolean {
        if (this.activeSalesChannel == null || !this.isAuthenticated) {
            return false;
        }

        if (this.permissions.products || this.permissions.calendar || this.permissions.reports || this.permissions.security) {
            return true;
        }

        return false;
    }

    showLinks() : boolean {
        return this.productivityInformationSystem && this.productivityInformationSystem.agentLinks.length > 0
            || this.productivityInformation && this.productivityInformation.agentLinks.length > 0;
    }

    showNews() : boolean {
        return this.productivityInformationSystem && this.productivityInformationSystem.agentMessages.length > 0
            || this.productivityInformation && this.productivityInformation.agentMessages.length > 0;
    }

    get permissions(): any {
        if(this.activeSalesChannel == null){
            return false;
        }
        return this.activeSalesChannel.permissions;
    }

    isDateInPast(dueDate: Date): boolean {
        return Math.sign(new Date(dueDate).getTime() - new Date().getTime()) == -1;
    }
}
