import { Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
    selector: 'saleschannel-form',
    template: `
        <!-- this form posts to the agent07.xml frameset of CRS -->
        <form method="post"
                action="{{ formUrl }}"
                target="{{ formTarget }}"
                #framesetForm>
            <input type="hidden"
                    value="0"
                    name="newagentsession"/>
            <input type="hidden"
                    value="{{ salesChannel }}"
                    name="salesid"/>
        </form>
    `,
    styleUrls: []
})
export class SalesChannelForm {
    @ViewChild('framesetForm', { static: false }) framesetForm: ElementRef;
    formUrl: string;
    formTarget: string = '_self';
    sessionId: string = null;
    salesChannel: number = null;

    constructor(private readonly cdRef: ChangeDetectorRef) {
        this.cdRef = cdRef;
        this.formUrl = `https://${environment.crsIntegration.domain}${environment.crsIntegration.mainFramesetRoute}`;
    }

    submit(salesChannel: number, sessionId: string, newWindow?: boolean): void {
        if (newWindow) {
            this.formTarget = '_blank';
        }
        this.sessionId = sessionId;
        this.salesChannel = salesChannel;
        this.cdRef.detectChanges();
        this.framesetForm.nativeElement.submit();
    }
}
