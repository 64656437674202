import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
    // Lazy Loaded Components
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    { path: 'admin', loadChildren: () => import('./adminTools/adminTools.module').then(m => m.AdminToolModule) },
    { path: 'cache', loadChildren: () => import('./cache/cache.module').then(m => m.CacheModule) },
    { path: 'channelbrowser', loadChildren: () => import('./channelBrowser/channelBrowser.module').then(m => m.ChannelBrowserModule,) },
    { path: 'conversionsuitetool', loadChildren: () => import('./coversionSuiteTool/conversionSuiteTool.module').then(m => m.ConversionSuiteToolModule,) },
    { path: 'guest', loadChildren: () => import('./customer/customerDetails/customer-details.module').then(m => m.CustomerDetailsModule,) },
    { path: 'guestsearch', loadChildren: () => import('./customer/customerSearch/customer-search.module').then(m => m.CustomerSearchModule,) },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'promotioninspector', loadChildren: () => import('./packageInspector/packageInspector.module').then(m => m.PackageInspectorModule) },
    { path: 'hurdletools', loadChildren: () => import('./productHurdleTools/productHurdleTools.module').then(m => m.ProductHurdleToolsModule) },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
    { path: 'rms/createaccount', loadChildren: () => import('./users/rms/rmsCreateAccount.module').then(m => m.RmsCreateAccountModule) },
    { path: 'saleschannelselector', loadChildren: () => import('./saleschannelselector/saleschannelselector.module').then(m => m.SalesChannelSelectorModule) },
    { path: 'searchoptions', loadChildren: () => import('./SearchOptions/searchOptions.module').then(m => m.SearchOptionsModule) },
    { path: 'selectredirect', loadChildren: () => import('./saleschannelselector/selectRedirect.module').then(m => m.SelectRedirectModule) },
    { path: 'sources', loadChildren: () => import('./sources/sources.module').then(m => m.SourcesModule) },
    { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
    { path: 'usermanagement', loadChildren: () => import('./users/userManagement/userManagementTools.module').then(m => m.UserManagementToolsModule) },
    { path: 'wallet', loadChildren: () => import('./wallet/wallet.module').then(m => m.WalletModule) },
    // Eagerly Loaded Components
    { path: 'home', component: HomeComponent },
    { path: '**', component: HomeComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
