// angular core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

// angular material
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';

// sphinnx core
import { httpInterceptorProviders } from './httpInterceptors';
import { DebounceInputDirective } from './debounceInput.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FilterPipe } from "./pipes/filter.pipe";
import { ShortNumberPipe } from "./pipes/short-number.pipe";
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeSincePipe } from "./pipes/TimeSince.pipe";
import { SalesChannelSelectorService } from "../saleschannelselector/saleschannelselector.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatExpansionModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatIconModule,
        MatChipsModule,
        MatPaginatorModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatRippleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        MatSortModule,
        MatTabsModule,
        ReactiveFormsModule
    ],
    declarations: [
        DebounceInputDirective,
        EllipsisPipe,
        FilterPipe,
        ShortNumberPipe,
        TimeSincePipe
    ],
    exports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatExpansionModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTableModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatIconModule,
        MatChipsModule,
        MatPaginatorModule,
        DebounceInputDirective,
        MatMenuModule,
        MatSlideToggleModule,
        MatRippleModule,
        EllipsisPipe,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatSortModule,
        MatTabsModule,
        FilterPipe,
        ShortNumberPipe,
        TimeSincePipe
    ],
    providers: [
        CookieService,
        HttpClientModule,
        httpInterceptorProviders,
        DebounceInputDirective,
        MatDatepickerModule,
        MatNativeDateModule,
        SalesChannelSelectorService
    ]
})
export class CoreModule { }
