export const environment = {
    release: true,
    apiEndpoint: '#{Sphinnx.Url.Api}',
    appBase: '#{Sphinnx.Content.AppBase}',
    deploymentName: '#{Octopus.deploymentName}',
    deploymentDate: '#{Octopus.deploymentDate}',
    guestSearchResultThreshold: 400,
    thirdPartyPaymentsMaxSearchRange: 90,
    paymentTotalReportMaxDaySearchRange: 365,
    inntopiaHelpBaseRoute: 'https://#{help.Http.Hostname}',
    crsIntegration: {
        domain: '#{Crs.Domain}',
        resellerOrganizeRoute: '/sqlxmlviaduct/agentadmin/ResellerOrganize.xml',
        mainFramesetRoute: '/sqlxmlviaduct/agent/agent07.xml',
        activateCustomerRoute: '/sqlxmlviaduct/Agent/customer07.xml',
        createCustomerRoute: '/agent/customer07.htm',
    },
    ecommIntegration: {
        domain: '#{Ecomm.Domain}',
        defaultRoute: '/ecomm/Checkout/EmptyItinerary',
        packageRoute: '/ecomm/package/packagebuilder',
        productAvailabilityRoute: '/ecomm/JSON/ProductPriceAvailability'
    },
    rmsIntegration: {
        domain: '#{Rms.Domain}',
    },
    adminIntegration: {
        adminHomePage: '/Admin09/InntopiaAdmin.aspx'
    }
};
