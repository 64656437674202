<div class="footer" *ngIf="!browserService.isInIframe() && !this.browserService.isInPopup()">
    <div class="footer__information">
        <img class="footer__img" src="assets/TextLogo-Black.png" alt="inntopia-logo-black">
        <p class="footer__information--text">©{{ currentYear }} Sterling Valley Systems, Inc.</p>
    </div>
    <p *ngIf="partnerServicesPhoneNumber" class="footer__partnerServicesInfo">{{ partnerServicesLabel }}: <a href="tel:{{ partnerServicesPhoneNumber }}">{{ partnerServicesPhoneNumber }}</a></p>
    <div class="footer__appStatusWrapper">
        <div *ngIf="status && status.componentDetails.db" class="footer__appStatus" (click)="openDialog()">
            <span class="appStatus__environment">{{ status.componentDetails.db }}</span>
            <span class="appStatus__environment"> | </span>
            <span class="appStatus__version">{{ status.version }}</span>
        </div>
    </div>
</div>
