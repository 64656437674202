import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { Health } from '../models/Health';

@Injectable({
    providedIn: 'root'
})
export class StatusService {
    private readonly api: string;
    private readonly status: Observable<Health>;

    constructor(private http: HttpClient) {
        this.api = environment.apiEndpoint;
        this.status = this.http.get<Health>(`${this.api}/status`);
    }

    getStatus(): Observable<Health> {
        return this.status;
    }

    getDetailedStatus(): Observable<Health> {
        return this.http.get<Health>(`${this.api}/status/detailed`);
    }

    getUser() {
        return this.http.get(`${this.api}/test`);
    }
}
