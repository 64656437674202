import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BrowserService } from './services/browser.service';
import { Health } from './models/Health';
import { environment } from "../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SupportInformation } from './models/SupportInformation';
import { InformationService } from "./services/information.service";
import { StatusService } from "./services/status.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    status: Health;
    partnerServicesPhoneNumber: string;
    partnerServicesLabel: string;
    supportEmail: string;
    currentYear: number;

    constructor(private cd: ChangeDetectorRef,
        private statusService: StatusService,
        public browserService: BrowserService,
        public snackBar: MatSnackBar,
        private infoService: InformationService) {
    }

    ngOnInit(): void {

        this.currentYear = new Date().getFullYear();

        this.statusService.getStatus().subscribe(
            status => {
                this.status = status;
                this.cd.markForCheck();
            }
        );

        this.infoService.getSupportInformation().subscribe(
            info => this.processSupportInfo(info)
        );
    }

    processSupportInfo(info: SupportInformation): void {
        this.partnerServicesPhoneNumber = info.partnerServicesPhone;
        this.partnerServicesLabel = info.partnerServicesLabel;
        this.supportEmail = info.supportEmail;
    }

    openDialog() {
        this.snackBar.open(environment.deploymentName + " | " + environment.deploymentDate, "Close");
    }
}
