import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';

// sphinnx shared components
import { SalesChannelForm } from './saleschannelForm.component';
import { ConfirmSubmissionDialog } from './ConfirmSubmissionDialog/confirmSubmissionDialog.component';
import { IntlPhoneInput } from './intlPhoneInput/intlPhoneInput.component';
import { AccountInfoForm } from "./accountInfoForm/accountInfoForm.component";
import { YearMonthDateFieldComponent } from "./yearMonthDateField/year-month-date-field.component";
import { CreateLinkDialog } from "./createLinkDialog/createLinkDialog.component";
import { ChannelPulldownComponent } from './channel-pulldown/channel-pulldown.component';
import { CreateMessageDialog } from "./create-message-dialog/create-message-dialog.component";

@NgModule({
    imports: [CoreModule],
    declarations: [
        ConfirmSubmissionDialog,
        CreateLinkDialog,
        CreateMessageDialog,
        SalesChannelForm,
        IntlPhoneInput,
        AccountInfoForm,
        YearMonthDateFieldComponent,
        ChannelPulldownComponent
    ],
    exports: [
        ConfirmSubmissionDialog,
        CreateLinkDialog,
        CreateMessageDialog,
        SalesChannelForm,
        IntlPhoneInput,
        AccountInfoForm,
        YearMonthDateFieldComponent,
        ChannelPulldownComponent
    ],
    providers: []
})
export class SharedModule { }
