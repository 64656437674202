<div *ngIf="!browserService.isInIframe() && status && status.componentDetails.db" class="header__environment">
    <p style="margin: unset">{{status.componentDetails.db}}</p>
</div>
<mat-toolbar color="primary" class="header" *ngIf="!browserService.isInIframe() && !this.browserService.isInPopup()">
    <div class="header__section">
        <img class="header__logoLink" src="assets/crs-logo-small.png" alt="crs-logo">
        <h1 class="header__title">Commerce Portal</h1>
        <h2 *ngIf="activeSalesChannel != null"
            class="header__salesChannel">
            {{ activeSalesChannel.name }} ({{ activeSalesChannel.id }})
        </h2>
    </div>
    <div class="header__section navigation">
        <div *ngIf="isAuthenticated" class="navigation__group">
            <a mat-button routerLink="/home">Home</a>
            <a mat-button routerLink="/saleschannelselector" (click)="clearSalesChannel()">Switch Channels</a>

            <ng-container *ngIf="permissions">
                <button *ngIf="permissions.reports" mat-button [matMenuTriggerFor]="reportsMenu">Reports <mat-icon>arrow_drop_down</mat-icon></button>
                <mat-menu #reportsMenu="matMenu">
                    <a mat-menu-item routerLink="/reports/thirdpartypayments">Third-Party Payments</a>
                </mat-menu>

                <button *ngIf="permissions.products || permissions.calendar || permissions.security || permissions.salesRules" mat-button [matMenuTriggerFor]="toolsMenu">Tools <mat-icon>arrow_drop_down</mat-icon></button>
                <mat-menu #toolsMenu="matMenu">
                    <a mat-menu-item *ngIf="permissions.products" routerLink="/promotioninspector">Promotion Inspector</a>
                    <a mat-menu-item *ngIf="permissions.calendar" routerLink="/hurdletools">Price Hurdle Tools</a>
                    <a mat-menu-item *ngIf="permissions.security" routerLink="/usermanagement">User Management</a>
                    <a mat-menu-item *ngIf="permissions.salesRules" routerLink="/sources">Source Management</a>
                    <a mat-menu-item *ngIf="permissions.salesRules" routerLink="/searchoptions">Search Options</a>
                </mat-menu>
            </ng-container>

            <button *ngIf="isAdmin" mat-button [matMenuTriggerFor]="adminMenu">Admin <mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #adminMenu="matMenu">
                <a mat-menu-item (click)="clearSalesChannel()" routerLink="/channelbrowser">Browse Channels</a>
                <a mat-menu-item (click)="clearSalesChannel()" routerLink="/conversionsuitetool">Conversion Suite Tool</a>
                <a mat-menu-item (click)="clearSalesChannel()" routerLink="/cache">Cache Tools</a>
                <a mat-menu-item (click)="clearSalesChannel()" routerLink="/admin">Inntopia Admin</a>
            </mat-menu>

            <a *ngIf="activeSalesChannel != null" mat-button (click)="navigateToLegacyCrs()">CRS</a>
            <saleschannel-form></saleschannel-form>

            <button mat-icon-button (click)="loadUserId()" [matMenuTriggerFor]="accountMenu"><mat-icon>person</mat-icon></button>
            <mat-menu #accountMenu="matMenu">
                <p class="accountText">{{userIdText}}</p>
                <a mat-menu-item routerLink="/account">Manage Account</a>
                <mat-divider></mat-divider>
                <a mat-menu-item target="_blank" rel="noopener" href="{{environment.inntopiaHelpBaseRoute}}"><mat-icon>open_in_new</mat-icon>Help</a>
                <mat-divider></mat-divider>
                <a mat-menu-item (click)="logoutAction()"><mat-icon>logout</mat-icon>Logout</a>
            </mat-menu>
        </div>

        <a *ngIf="!isAuthenticated" mat-button (click)="loginAction()">Login</a>
    </div>
</mat-toolbar>
<div *ngIf="!browserService.isInIframe() && !this.browserService.isInPopup() && this.dismissibleMessages">
    <mat-card *ngFor="let message of dismissibleMessages" class="dismissibleMessage" [ngClass]="setSeverityColorClass(message.severity)">
        <mat-card-content>
            <mat-icon>{{InfoService.getMessageSeverityIcon(message.severity)}}</mat-icon>
            <p>{{message.message}}</p>
            <button *ngIf="isAuthenticated" class="close-button" mat-icon-button (click)="dismissMessage(message)"><mat-icon>close</mat-icon></button>
        </mat-card-content>
    </mat-card>
</div>
