<div class="home">

    <mat-card class="home__infoBox home__infoBox--main">
        <mat-card-header>
            <mat-card-title>Welcome!</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>The Commerce Portal is your go-to access point for CRS, RMS, and for quick links to several helpful tools and reports. This space will continue to evolve over time with even more helpful features and links!</p>
            <p *ngIf="activeSalesChannel != null && isAuthenticated">Check out what's available in the top right corner or open up CRS or RMS.</p>
            <p *ngIf="activeSalesChannel == null && isAuthenticated">Select a sales channel to get started.</p>
            <p class="home__authMessage home__authMessage--notAuth" *ngIf="!isAuthenticated">You are not authenticated. <a mat-flat-button [routerLink]="['/login']" [queryParams]="{ redirect_uri: '/home' }">Click here to login.</a></p>
        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="activeSalesChannel != null" mat-raised-button class="home__buttonButtons home__buttonButtons--crs" color="primary" (click)="navigateToLegacyCrs()">CRS</button>
            <saleschannel-form></saleschannel-form>
            <button *ngIf="activeSalesChannel != null" mat-raised-button class="home__buttonButtons home__buttonButtons--rms" color="primary" (click)="navigateToRMS()">RMS</button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="home__infoBox home__infoBox--links" *ngIf="showQuickLinks()">
        <mat-card-header>
            <mat-card-title>Quick Links</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p><a mat-button *ngIf="permissions.products" [routerLink]="['/promotioninspector']"><mat-icon>search</mat-icon> Promotion Inspector</a></p>
            <p><a mat-button *ngIf="permissions.calendar" [routerLink]="['/hurdletools']"><mat-icon>build</mat-icon> Price Hurdle Tools</a></p>
            <p><a mat-button *ngIf="permissions.reports" [routerLink]="['/reports/thirdpartypayments']"><mat-icon>list_alt</mat-icon> Third-Party Payments</a></p>
            <p><a mat-button *ngIf="permissions.security" [routerLink]="['/usermanagement']"><mat-icon>people</mat-icon> User Management</a></p>
        </mat-card-content>
    </mat-card>

    <mat-card class="home__infoBox home__infoBox--reminders" *ngIf="this.isAuthenticated && productivityInformationSystem">
        <mat-card-header>
            <mat-card-title>Reminders</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="productivityInformationSystem.reminders.length > 0">
            <mat-list class="home__list">
                <mat-list-item class="home__listItem" *ngFor="let reminder of productivityInformationSystem.reminders">
                    <div matListItemLine class="home__listItem__title">{{reminder.message}}</div>
                    <div matListItemLine class="home__listItem__message" [style.color]="isDateInPast(reminder.dueDate) ? 'red':''">Due {{reminder.dueDate | TimeSince}}</div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-content *ngIf="productivityInformationSystem.reminders.length <= 0">
            <p>No active reminders!</p>
        </mat-card-content>
    </mat-card>

    <mat-card class="home__infoBox home__infoBox--news" *ngIf="showNews()">
        <mat-card-header>
            <mat-card-title>News</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="productivityInformationSystem && productivityInformationSystem.agentMessages.length > 0">
            <mat-list class="home__list">
                <mat-list-item class="home__listItem" *ngFor="let message of productivityInformationSystem.agentMessages">
                    <div matListItemTitle class="home__listItem__title">{{message.title}}</div>
                    <div matListItemLine class="home__listItem__date">{{message.dateActive | TimeSince}}</div>
                    <div matListItemLine class="home__listItem__message">{{message.message}}</div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-content *ngIf="productivityInformation">
            <mat-divider *ngIf="productivityInformation.agentMessages.length > 0 && (productivityInformationSystem && productivityInformationSystem.agentMessages.length > 0)"></mat-divider>
            <mat-list class="home__list">
                <mat-list-item class="home__listItem" *ngFor="let message of productivityInformation.agentMessages" >
                    <div matListItemTitle class="home__listItem__title">{{message.title}}</div>
                    <div matListItemLine class="home__listItem__date">{{message.dateActive | TimeSince}}</div>
                    <div matListItemLine class="home__listItem__message">{{message.message}}</div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
    </mat-card>

    <mat-card class="home__infoBox home__infoBox--agentLinks" *ngIf="showLinks()">
        <mat-card-header>
            <mat-card-title>Links</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="productivityInformationSystem && productivityInformationSystem.agentLinks.length > 0">
            <mat-list class="home__list">
                <mat-list-item class="home__listItem" *ngFor="let link of productivityInformationSystem.agentLinks">
                    <div matListItemTitle class="home__listItem__title">
                        <a target="_blank" rel="noopener" href="{{link.url}}">{{link.title}} </a>
                    </div>
                    <div matListItemLine class="home__listItem__message">{{link.description}}</div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-content *ngIf="productivityInformation">
            <mat-divider *ngIf="productivityInformation.agentLinks.length > 0 && (productivityInformationSystem && productivityInformationSystem.agentLinks.length > 0)"></mat-divider>
            <mat-list class="home__list">
                <mat-list-item class="home__listItem" *ngFor="let link of productivityInformation.agentLinks">
                    <div matListItemTitle class="home__listItem__title">
                        <a target="_blank" rel="noopener" href="{{link.url}}">{{link.title}}</a>
                    </div>
                    <div matListItemLine class="home__listItem__message">{{link.description}}</div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>
